



























































































































import Vue from "vue";
import { Grid, GridItem, Image, Icon, Popup, Picker, Tabbar, TabbarItem, Swipe, SwipeItem, Button, Sticky, Toast, Dialog, PullRefresh, NoticeBar } from "vant";
import IndexGridItem from "./index-grid-item.vue";
import Component from "vue-class-component";
import wx from "weixin-js-sdk";

interface Manager {
  businessName: string;
  managerId: number;
  service: number;
}

interface Menu {
  title: string;
  key: string;
  show: boolean;
  url?: string;
  to?: string;
  img: string;
  onClick?(): void;
  badge?: string;
}

enum Tab {
  Business = "business",
  Device = "device",
  Account = "account",
  Setting = "setting"
}

interface Message {
  id: number;
  title: string;
  content: string;
}

@Component({
  components: {
    IndexGridItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Button.name]: Button,
    [Sticky.name]: Sticky,
    [PullRefresh.name]: PullRefresh,
    [NoticeBar.name]: NoticeBar,
    [Dialog.Component.name]: Dialog.Component
  }
})
export default class Index extends Vue {
  commitHash = __COMMIT_HASH__;
  active = Tab.Business;
  Tab = Tab;
  repairTab = { title: "设备报修", key: "deviceRepair", show: false, to: "/device/repair", img: "/images/index/设备报修.png" } as Menu;
  cabinetServicePlanTab = { title: "设备服务费", key: "cabinetServicePlan", show: false, to: "/service-plan/cabinet/plan-list", img: "/images/index/设备服务费.png" } as Menu;
  tabs = [
    {
      key: Tab.Business,
      label: "运营",
      activeImg: "/images/index/标签栏/订单-点击状态.png",
      inActiveImg: "/images/index/标签栏/订单-正常状态.png",
      show: false,
      menus: [
        {
          name: "订单",
          len: 0,
          list: [
            { title: "租售订单", key: "batteryPlanOrder", show: false, url: "/web/manager/battery_plan_order_list", img: "/images/index/租售订单.png" },
            { title: "换电订单", key: "batteryReplaceOrder", show: false, url: "/web/manager/battery_replace_order_list", img: "/images/index/换电订单.png" },
            { title: "充电订单", key: "batteryChargeOrder", show: false, to: "/battery-order/list", img: "/images/index/充电订单.png" },
            { title: "包月订单", key: "monthRentOrder", show: false, url: "/web/manager/month_rent_order/list", img: "/images/index/包月订单.png" },
            { title: "充值订单", key: "rechargeOrder", show: false, url: "/manager/recharge/recharge_order_list.html", img: "/images/index/充值订单.png" },
            { title: "debug", key: "sample", show: false, to: "sample", img: "/images/index/充值订单.png" }
          ]
        },
        {
          name: "运营",
          len: 0,
          list: [
            { title: "站点管理", key: "siteList", show: false, url: "/web/manager/site/site_list", img: "/images/index/站点管理.png" },
            { title: "用户管理", key: "userList", show: false, url: "/web/manager/user/user_list", img: "/images/index/用户管理.png" },
            { title: "商品管理", key: "goodsManage", show: false, url: "/web/manager?page=goods/manager_goods_list", img: "/images/index/商品管理.png" },
            { title: "商品关联", key: "bikeMapGoodsManage", show: false, url: "/web/manager?page=manager_bind_bike_goods_list", img: "/images/index/商品关联车辆.png" },
            { title: "邀请赠送", key: "shareRecord", show: false, url: "/web/manager/user/share_record", img: "/images/index/邀请赠送记录.png" },
            { title: "优惠券", key: "couponGiveaway", show: false, to: "/coupon/giveaway", img: "/images/index/优惠券发放.png" },
            { title: "团队租售", key: "teamBatteryPlan", show: false, url: "/web/manager/goods/team_plan_list", img: "/images/index/租售订单.png" },
            this.repairTab,
            this.cabinetServicePlanTab
          ]
        }
      ]
    },
    {
      key: Tab.Device,
      label: "设备",
      show: false,
      activeImg: "/images/index/标签栏/设备-点击状态.png",
      inActiveImg: "/images/index/标签栏/设备-正常状态.png",
      menus: [
        {
          name: "设备",
          len: 0,
          list: [
            { title: "扫码开舱", key: "takeBattery", show: false, url: "/web/manager?page=cabin_input", img: "/images/index/扫码取电池.png" },
            { title: "设备列表", key: "deviceList", show: false, to: "/device/statistics", img: "/images/index/设备列表.png" },
            { title: "查看硬件", key: "hardWareDetail", show: false, to: "/device/search", img: "/images/index/硬件上传记录.png" },
            { title: "换电记录", key: "cabinetRoomOpenRecord", show: false, url: "/web/manager/cabinet/room_open_record.html", img: "/images/index/换电记录.png" },
            { title: "任务列表", key: "taskList", show: false, url: "/web/manager/task_list", img: "/images/index/任务列表.png" },
            { title: "维修记录", key: "repairList", show: false, url: "/web/manager/repair_list", img: "/images/index/邀请赠送设置.png" },
            { title: "SIM卡", key: "simCard", show: false, url: "/web/manager/sim_list", img: "/images/index/SIM卡列表.png" },
            { title: "硬件录入", key: "deviceBind", show: false, url: "/web/manager/device_bind_index", img: "/images/index/硬件绑定录入.png" },
            { title: "厂家绑定", key: "factoryDeviceBind", show: false, url: "/web/manager/factory/device_bind.html", img: "/images/index/硬件生产厂家绑定.png" },
            { title: "运营商绑定", key: "deviceBindBusiness", show: false, to: "/device/bind-business", img: "/images/index/硬件运营商绑定.png" },
            { title: "设备安装", key: "deviceInstall", show: false, to: "/device/install/0", img: "/images/index/设备安装.png" },
            { title: "ICCID记录", key: "iccid2", show: false, to: "/internal/sim", img: "/images/index/SIM卡列表.png" }
          ]
        }
      ]
    },
    {
      key: Tab.Account,
      label: "收益",
      show: false,
      activeImg: "/images/index/标签栏/收益-点击状态.png",
      inActiveImg: "/images/index/标签栏/收益-正常状态.png",
      menus: [
        {
          name: "收益",
          len: 0,
          list: [
            { title: "我的流水", key: "myAccount", show: false, url: "/web/manager/profit/record", img: "/images/index/我的账户.png" },
            { title: "收益统计", key: "myAccountStat", show: false, to: "/account/stat", img: "/images/index/收益统计.png" },
            { title: "运营统计", key: "businessStatistics", show: false, url: "/web/manager/business/statistics", img: "/images/index/运营数据.png" },
            { title: "运营流水", key: "businessProfitRecord", show: false, url: "/web/manager/business/profit_record", img: "/images/index/运营商收益详单.png" },
            { title: "分润详单", key: "accountProfitRecord", show: false, url: "/web/manager/profit/account_record_redirect", img: "/images/index/分润人收益详单.png" },
            { title: "账单导出", key: "billExport", show: false, to: "/bill/bill-export", img: "/images/index/账单导出.png" },
            { title: "平台服务费", key: "levy", show: false, to: "/levy/levy", img: "/images/index/服务费.png" }
          ]
        }
      ]
    },
    {
      key: Tab.Setting,
      label: "设置",
      show: false,
      activeImg: "/images/index/标签栏/设置-点击状态.png",
      inActiveImg: "/images/index/标签栏/设置-正常状态.png",
      menus: [
        {
          name: "设置",
          len: 0,
          list: [
            { title: "运营商管理", key: "business", show: false, url: "/web/manager/business/list", img: "/images/index/运营商管理.png" },
            { title: "厂家管理", key: "factory", show: false, url: "/web/manager/factory/list", img: "/images/index/生产厂家管理.png" },
            { title: "权限管理", key: "manager", show: false, url: "/web/manager/manager_list", img: "/images/index/权限管理.png" },
            { title: "操作日志", key: "actionLog", show: false, url: "/web/manager/cabinet/action_log", img: "/images/index/操作日志.png" },
            { title: "服务收费", key: "servicePlan", show: false, to: "/service-plan/plan-list/business", img: "/images/index/平台服务方案.png" },
            { title: "平台收费", key: "servicePlanPlatform", show: false, to: "/service-plan/plan-list/platform", img: "/images/index/平台服务方案.png" },
            { title: "电宝迁移", key: "dianbaoTransfer", show: false, to: "/migrate-agency-from-dianbao", img: "/images/index/电宝迁移.png" },
            { title: "平台切换", key: "platformSwitch", show: false, onClick: this.showPlatformPicker, img: "/images/index/平台切换.png" },
            { title: "审核列表", key: "review", show: false, to: "/review/audit/list", img: "/images/index/审核列表.png" },
            { title: "轮播图", key: "slide", show: false, url: "/web/manager/slide/list", img: "/images/index/轮播图管理.png" },
            { title: "服务费管理", key: "levyFinance", show: false, to: "/levy/levy-finance", img: "/images/index/服务费.png" }
          ]
        }
      ]
    }
  ];

  avatarUrl = "./image/avatar.png";
  name = "";
  phone = "";
  businessName = "";
  numberOfUnreadMessage = 0;
  numberOfActiveServicePlan = 0;
  isAllowMyAccount = false;
  account = {
    isWithdrawAccountSet: false,
    isAllowWithdrawApply: false,
    isWithdrawApplying: false,
    isAllowWithdrawAccountModify: false,
    isWithdrawAccountModifyApplying: false,
    cashAllowAmount: 0,
    cashSuccessAmount: 0,
    cashApplyAmount: 0,
    isWithdrawAccountCheckNeeded: false,
    isWithdrawAccountChecked: false,
    isWithdrawAccountConfirmApplying: false
  };
  managerPicker = {
    show: false,
    list: [] as Manager[],
    default: 0
  };
  platformPicker = {
    show: false,
    list: []
  };
  messageList = [] as Message[];
  showMessageDialog = false;
  messageIdx = 0;
  message = null as Message | null;
  buttons = [] as string[];
  isRefreshing = false;
  showSwipe = false;

  platformId = 0;
  showPlatformDialog = false;

  cabinetServicePlan = {
    dueDay: 30,
    dueCount: 0,
    expireCount: 0
  };

  menuLock = false;

  toVideoIntro() {
    this.$toMiniAppWeb("https://app.letfungo.com/intro/intro.html");
  }
  toProfitConfigCheck() {
    this.$toMiniAppWeb("/web/manager/business/profit_config_list_manager?hideReplace=1&showIntro=1");
  }
  toMessageList() {
    this.$toMiniAppWeb("/web/user/message/message_list");
  }
  toSevicePlanIntro() {
    this.$axios.post("/api/manage/readAnnounce", { announceId: 2 }).then(() => {
      this.$toMiniAppWeb("https://app.letfungo.com/web/manager/v/app/image?src=" + encodeURIComponent("/images/服务费0601.png"));
    });
  }
  toCabinetServicePlan() {
    this.$router.push("/service-plan/cabinet/plan-list");
  }
  activated() {
    this.showSwipe = true;
    this.getManager();
  }
  deactivated() {
    this.showSwipe = false;
  }
  get isOnAccountTab() {
    return this.isAllowMyAccount && this.active == Tab.Account;
  }
  get tabsShow() {
    return this.tabs.filter(t => t.show);
  }
  get isWithdrawDisabled() {
    return this.account.isWithdrawApplying || this.account.isWithdrawAccountModifyApplying || this.account.isWithdrawAccountConfirmApplying;
  }
  get withdrawButtonText() {
    return this.account.isWithdrawApplying ? "提现审核中" : this.account.isWithdrawAccountModifyApplying ? "提现设置审核中" : this.account.isWithdrawAccountConfirmApplying ? "账号确认资料审核中" : "提现";
  }
  onRefresh() {
    this.getManager().then(() => {
      this.isRefreshing = false;
    });
  }
  onTabChange(key: string) {
    const swiper = this.$refs.swiper as Swipe;
    swiper.swipeTo(this.tabsShow.findIndex(tab => tab.key == key));
  }
  onSwipeChange(index: number) {
    this.active = this.tabsShow[index].key;
  }
  nextMessage() {
    const message = this.messageList[this.messageIdx];
    if (message) {
      this.$axios.post("/api/manage/message/messageRead", { messageId: message.id });
    }
    if (this.messageList.length === this.messageIdx + 1) {
      this.showMessageDialog = false;
    } else {
      this.messageIdx++;
    }
  }
  getManager() {
    return this.$axios.post("/api/manage/getManger").then(e => {
      const res = e.data;
      const data = res.data;
      this.buttons = data.buttons;
      this.avatarUrl = data.avatarUrl;
      this.phone = data.phone;
      this.name = data.name;
      this.businessName = data.businessName;
      this.numberOfUnreadMessage = data.numberOfUnreadMessage;
      this.numberOfActiveServicePlan = data.numberOfActiveServicePlan;
      this.account = data.account || {};
      this.platformId = data.platformId;
      this.messageList = data.messageList || [];
      if (this.messageList.length > 0) {
        this.showMessageDialog = true;
      }

      localStorage.platformId = this.platformId;

      if (this.platformId == 3) {
        const showPlatformDialog = false;
        // if (data.checkCabinetServicePlan) {
        //   showPlatformDialog = true;
        //   this.$axios.post("/api/manage/readAnnounce", { announceId: 1 });
        // }
        // if (data.businessId == 1109 || data.businessId == 1139) {
        //   if (data.phone != 17751725646 && data.phone != 13914966041) {
        //     if (data.isSiteManager) {
        //       showPlatformDialog = false;
        //     }
        //   }
        // }
        this.showPlatformDialog = showPlatformDialog;
      }
      if (this.platformId == 3 && this.tabs[0].menus[0].name != "常用功能") {
        this.tabs[0].menus.unshift({
          name: "常用功能",
          len: 0,
          list: [
            { title: "设备列表", key: "deviceList", show: false, to: "/device/statistics", img: "/images/index/设备列表.png" },
            { title: "站点管理", key: "siteList", show: false, url: "/web/manager/site/site_list", img: "/images/index/站点管理.png" },
            { title: "查看硬件", key: "hardWareDetail", show: false, to: "/device/search", img: "/images/index/硬件上传记录.png" },
            { title: "安装/变更", key: "deviceInstall", show: false, to: "/device/install/0", img: "/images/index/设备安装.png" }
          ]
        });
      }
      if (data.id == 10080 || data.id == 9132) {
        this.buttons.push("iccid2");
        this.buttons.push("sample");
      }
      if (data.checkCabinetServicePlan) {
        this.buttons.push("cabinetServicePlan");
        this.$axios.post("/api/manage/servicePlan/getDueCabinetInfo", { dueDay: this.cabinetServicePlan.dueDay }).then(res => {
          const dueData = res.data.data;
          this.cabinetServicePlan.dueCount = dueData.dueCount;
          this.cabinetServicePlan.expireCount = dueData.expireCount;
          this.cabinetServicePlanTab.badge = dueData.dueCount + dueData.expireCount || undefined;
        });
      } else {
        this.cabinetServicePlan.dueCount = 0;
        this.cabinetServicePlan.expireCount = 0;
      }

      this.isAllowMyAccount = Boolean(data.account);
      for (const tab of this.tabs) {
        tab.show = false;
        for (const menus of tab.menus) {
          menus.len = 0;
          for (const menu of menus.list) {
            menu.show = false;
            for (const key of this.buttons) {
              if (key == menu.key) {
                menu.show = true;
                ++menus.len;
                tab.show = true;
              }
            }
          }
        }
      }
      const swiper = this.$refs.swiper as Swipe;
      if (swiper) {
        const swiperIndex = this.tabsShow.findIndex(tab => tab.key == this.active);
        if (swiperIndex === -1) {
          swiper.swipeTo(0, { immediate: true });
          this.active = this.tabsShow[0].key;
        } else {
          swiper.swipeTo(swiperIndex, { immediate: true });
        }
      }

      this.managerPicker.list = data.managerIds;
      this.managerPicker.default = data.managerIds.findIndex((e: Manager) => e.managerId === data.managerId);

      const service = data.service;
      if ((service & 244) === 244) {
        localStorage.myCacheShowReplace = 1;
        localStorage.myCacheShowPlan = 1;
      } else {
        localStorage.myCacheShowReplace = 0;
        localStorage.myCacheShowPlan = 0;
      }
      if ((service & 3) === 3) {
        localStorage.myCacheShowCharge = 1;
      } else {
        localStorage.myCacheShowCharge = 0;
      }
      this.$loadDisplay();

      if (data.repairCount) {
        this.repairTab.badge = data.repairCount;
      }

      if (data.buttons.includes("showLog")) {
        localStorage.showLog = true;
      } else {
        localStorage.removeItem("showLog");
      }

      if (data.notifySms) {
        if (new Date().getTime() - Number(localStorage.notifyConfirmedAt || 0) > 300 * 1000) {
          Dialog.confirm({
            message: data.notifySms.message,
            showCancelButton: false,
            confirmButtonText: "我已知悉"
          }).then(() => {
            localStorage.notifyConfirmedAt = new Date().getTime();
          });
        }
      }

      if (data.levy) {
        if (data.levy.lockAccess) {
          this.menuLock = true;
        }
        if (data.levy.isWarn) {
          if (new Date().getTime() - Number(localStorage.levyWarnConfirmedAt || 0) > 3600 * 1000) {
            // 1小时不重复提示
            Dialog.confirm({
              allowHtml: true,
              message: `${data.levy.warnMsg}<br/> <span style="color:red"> 注意：逾期后相关功能将无法使用！ </span>`,
              ...(data.levy.isPlatform
                ? {
                    showCancelButton: false,
                    confirmButtonText: "我已知悉"
                  }
                : {
                    showCancelButton: true,
                    cancelButtonText: "我已知悉",
                    confirmButtonText: "充值"
                  })
            })
              .then(() => {
                localStorage.levyWarnConfirmedAt = new Date().getTime();
                // eslint-disable-next-line no-empty
                if (data.levy.isPlatform) {
                } else {
                  this.$router.push("/levy/levy");
                }
              })
              .catch(() => {
                localStorage.levyWarnConfirmedAt = new Date().getTime();
              });
          }
        }
      }
    });
  }
  withdrawBinding() {
    if (!this.account.isAllowWithdrawAccountModify) {
      Toast.fail("您的账户暂无提现设置修改权限，请联系管理员");
      return;
    }
    if (this.account.isWithdrawAccountModifyApplying) {
      Toast.fail("您的提现设置正在审核中， 请耐心等待");
      return;
    }
    wx.miniProgram.navigateTo({ url: "../../profit-account/withdraw/bind-wx" });
  }
  withdrawAccountCheck() {
    if (!this.account.isWithdrawAccountCheckNeeded) {
      Toast.fail("无需进行账号确认");
      return;
    }
    if (this.account.isWithdrawAccountChecked) {
      Toast.fail("账号已经完成确认");
      return;
    }
    if (this.account.isWithdrawAccountConfirmApplying) {
      Toast.fail("您的账号确认资料正在审核中， 请耐心等待");
      return;
    }
    this.$router.push("/review/apply/account-confirm");
  }
  applyForTransfer() {
    if (!this.account.isAllowWithdrawApply) {
      Toast.fail("您的账户暂无提现权限， 请联系管理员");
      return;
    }
    if (!this.account.isWithdrawAccountSet && !this.account.isAllowWithdrawAccountModify) {
      Toast.fail("您尚未完成提现设置");
      return;
    }
    if (!this.account.isWithdrawAccountSet && this.account.isAllowWithdrawAccountModify) {
      Dialog.confirm({ message: "您尚未完成提现设置, 是否前往设置" }).then(() => {
        this.withdrawBinding();
      });
      return;
    }
    if (this.account.isWithdrawAccountCheckNeeded) {
      if (!this.account.isWithdrawAccountChecked) {
        if (this.account.isWithdrawAccountConfirmApplying) {
          Toast.fail("您的账号确认资料正在审核中， 请耐心等待");
          return;
        } else {
          Dialog.confirm({ message: "您尚未完成账号确认, 是否前往" }).then(() => {
            this.withdrawAccountCheck();
          });
          return;
        }
      }
    }
    if (this.account.isWithdrawApplying) {
      Toast.fail("您的提现正在审核中， 请耐心等待");
      return;
    }
    this.$toUrl("/web/manager/profit/withdraw/apply");
  }
  onConfirmManagerSwitch(value: Manager, index: number) {
    this.managerPicker.show = false;
    this.managerPicker.default = index;
    this.$axios.post("/api/manage/switchManger", { managerId: value.managerId }).then(() => {
      localStorage.levyWarnConfirmedAt = ''; // 切换运营商后，重新提示服务费
      this.getManager();
    });
  }
  showPlatformPicker() {
    return this.$axios.post("/api/manage/getPlatformListForPick").then(e => {
      const data = e.data.data;
      this.platformPicker.list = data;
      this.platformPicker.show = true;
    });
  }
  onConfirmPlatformSwitch(value: { id: number; name: string }) {
    this.platformPicker.show = false;
    return this.$axios.post("/api/manage/switchPlatform", { platformId: value.id }).then(e => {
      const data = e.data.data;
      localStorage.token = data.token;
      this.getManager().then(() => {
        Toast.success("切换成功");
      });
    });
  }
}
