import { render, staticRenderFns } from "./index-grid-item.vue?vue&type=template&id=5577b406&scoped=true"
import script from "./index-grid-item.vue?vue&type=script&lang=ts"
export * from "./index-grid-item.vue?vue&type=script&lang=ts"
import style0 from "./index-grid-item.vue?vue&type=style&index=0&id=5577b406&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5577b406",
  null
  
)

export default component.exports