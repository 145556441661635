




import Vue from "vue";
import { GridItem, Image } from "vant";

export default Vue.extend({
  props: ["img", "url", "show", "title", "to", "badge", "isDisabled"],
  components: {
    [GridItem.name]: GridItem,
    [Image.name]: Image
  },
  methods: {
    onClick() {
      if (this.isDisabled) return;
      if (this.to) return;
      if (this.url) {
        this.$toUrl(this.url);
      }
    }
  }
});
